const { lazy } = require("react")

const Pages = {
  Signin: lazy(() => import("Packer/Pages/Auth/Signin")),
  RestorePassword: lazy(() => import("Packer/Pages/Auth/RestorePassword")),

  PackerAttachCurierV2: lazy(() => import("Packer/Pages/AttachCurierV2")),
  IncomeFromStorage: lazy(() => import("Packer/Pages/Disposal/List")),

  PackerLists: lazy(() => import("Packer/Pages/Lists")),
  PackerUpdate: lazy(() => import("Packer/Pages/Lists/Update")),

  Containers: lazy(() => import("Packer/Pages/PackageContainers")),

  Store: lazy(() => import("Packer/Pages/Store")),
  IncomeOrder: lazy(() => import("Packer/Pages/IncomeOrder")),
}

export default Pages
