import PackerLayout from "Packer/Layout"
import { Navigate } from "react-router-dom"

import Pages from "./Pages"

const AdminRoutes = [
  {
    path: "",
    element: <PackerLayout />,
    children: [
      {
        path: "",
        element: <Pages.PackerLists />,
      },
      {
        path: "list/:id",
        element: <Pages.PackerUpdate />,
      },
      {
        path: "attach-curier",
        element: <Pages.PackerAttachCurierV2 />,
      },
      {
        path: "income",
        element: <Pages.IncomeFromStorage />,
      },
      {
        path: "box",
        element: <Pages.Containers />,
      },
      {
        path: "store",
        element: <Pages.Store />,
      },
      {
        path: "income-order",
        element: <Pages.IncomeOrder />,
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to="/" />,
    children: [],
  },
]

if (process.env.REACT_APP_MODE === "development") {
  AdminRoutes.push()
}

export default AdminRoutes
